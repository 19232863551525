import React from "react";
import ReactPlayer from "react-player";
import Logo from "../components/logo.js";
import "../Assets/css/home.css";
const Zoom = props => (
  <div className="content__section">
    <div id="box" className="content__main grid-container">
      <div className="item1" />
      <div className="item2">
        <div className="holder" />
        <div className="empty">
          <Logo />
        </div>
      </div>
      <div className="item3" />
      <div className="item4" />
    </div>

    <div id="Trailer" className="page-trailer">
      <div id="wrapper" className="video-wrapper">
        <ReactPlayer
          url=" https://static.framer.com/x/frontpage/hero.mp4"
          playing
          muted
          loop
          width="100%"
          height="100%"
        />
      </div>
    </div>
    <h2 className="content__text">Scroll↓</h2>
  </div>
);

export default Zoom;
