import React from "react";
import { Link } from "gatsby";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import "../Assets/css/base.css";
import "../Assets/css/demo1.css";
import "../Assets/css/home.css";
import SEO from "../components/seo";
import Header from "../components/header";
import Zoom from "../components/herologo";
import ReactPlayer from "react-player";

function ScrollListner() {
  var box = document.getElementById("box", "wrapper"),
    docHeight = document.documentElement.offsetHeight;
  var wrapper = document.getElementById("wrapper");
  // normalize scroll position as percentage
  if (document.body.scrollTop > -1) {
    var scrolled = window.scrollY / 20;
    if (scrolled <= 20) {
      console.log(scrolled);
      var hello = 1 + scrolled;
      var hey = 0.8 + scrolled / 100;
      console.log(hello);
      var transformValue = "scale(" + hello + ")";
      var transformValue2 = "scale(" + hey + ")";
      document.getElementById("Trailer").className = "page-trailer";
      document.getElementById("box").className = "grid-container";
    } else {
      document.getElementById("box").className = "grid-container-hidden";
      document.getElementById("Trailer").className = "posit";
      document.getElementById("wrapper").className = "video-wrapper-out";
    }
  } else if (hello > 1) {
    document.getElementById("box").className = "grid-container vis";
  }
  box.style.WebkitTransform = transformValue;
  box.style.MozTransform = transformValue;
  box.style.OTransform = transformValue;
  box.style.transform = transformValue;

  wrapper.style.WebkitTransform = transformValue2;
  wrapper.style.MozTransform = transformValue2;
  wrapper.style.OTransform = transformValue2;
  wrapper.style.transform = transformValue2;
}

function listnerFunc() {
  window.addEventListener("scroll", ScrollListner, false);
}

class IndexPage extends React.Component {
  eventListening() {
    window.addEventListener("load", listnerFunc, false);
  }

  componentDidMount() {
    const charming = require("charming");
    document.documentElement.className = "js";
    var supportsCssVars = function() {
      var e,
        t = document.createElement("style");
      return (
        (t.innerHTML = "root: { --tmp-var: bold; }"),
        document.head.appendChild(t),
        (e = !!(
          window.CSS &&
          window.CSS.supports &&
          window.CSS.supports("font-weight", "var(--tmp-var)")
        )),
        t.parentNode.removeChild(t),
        e
      );
    };
    supportsCssVars() ||
      alert(
        "Please view this demo in a modern browser that supports CSS Variables."
      );

    {
      const chars = ["$", "%", "#", "@", "&", "(", ")", "=", "*", "/"];
      const charsTotal = chars.length;
      const getRandomInt = (min, max) =>
        Math.floor(Math.random() * (max - min + 1)) + min;

      class Entry {
        constructor(el) {
          this.DOM = { el: el };
          this.DOM.image = this.DOM.el.querySelector(".content__img");
          this.DOM.title = {
            word: this.DOM.el.querySelector(".content__text")
          };
          charming(this.DOM.title.word);
          this.DOM.title.letters = Array.from(
            this.DOM.title.word.querySelectorAll("span")
          );
          this.DOM.title.letters.forEach(
            letter => (letter.dataset.initial = letter.innerHTML)
          );
          this.lettersTotal = this.DOM.title.letters.length;
          observer.observe(this.DOM.el);
        }
        enter(direction = "down") {
          this.DOM.title.word.style.opacity = 1;
          this.timeouts = [];
          this.complete = false;
          let cnt = 0;
          this.DOM.title.letters.forEach((letter, pos) => {
            const timeout = setTimeout(() => {
              letter.innerHTML = chars[getRandomInt(0, charsTotal - 1)];
              setTimeout(() => {
                letter.innerHTML = letter.dataset.initial;
                ++cnt;
                if (cnt === this.lettersTotal) {
                  this.complete = true;
                }
              }, 100);
            }, pos * 80);
            this.timeouts.push(timeout);
          });
        }

        exit(direction = "down") {
          this.DOM.title.word.style.opacity = 0;
          if (this.complete) return;
          for (let i = 0, len = this.timeouts.length; i <= len - 1; ++i) {
            clearTimeout(this.timeouts[i]);
          }
        }
      }

      let observer;
      let current = -1;
      let allentries = [];
      const sections = Array.from(
        document.querySelectorAll(".content__section")
      );
      // Preload all the images in the page..
      document.body.classList.remove("loading");
      if ("IntersectionObserver" in window) {
        document.body.classList.add("ioapi", "demo-1");

        observer = new IntersectionObserver(
          entries => {
            entries.forEach(entry => {
              if (entry.intersectionRatio > 0.5) {
                const newcurrent = sections.indexOf(entry.target);
                if (newcurrent === current) return;
                const direction = newcurrent > current;
                if (current >= 0) {
                  allentries[current].exit(direction ? "down" : "up");
                }
                allentries[newcurrent].enter(direction ? "down" : "up");
                current = newcurrent;
              }
            });
          },
          { threshold: 0.5 }
        );

        sections.forEach(section => allentries.push(new Entry(section)));
      }
    }

    this.eventListening();
  }

  componentWillUnmount() {
    window.removeEventListener("load", listnerFunc);
    window.removeEventListener("scroll", ScrollListner);
  }

  componentWillMount() {
    if (typeof window !== "undefined") {
      window.addEventListener("load", listnerFunc);
      window.addEventListener("scroll", ScrollListner);
    }
  }

  render() {
    return (
      <div className="demo-1">
        <SEO
          title="Makook · Not your average studio "
          description="An out of space design studio from Algiers focused on creating forward-thinking brand identities,
           websites, apps, 3D art and animation for clients who dare to be different. 
           We go far beyond by combining design with cinematography for the really really out of this world experiences.  "
          image={
            "https://makook.space" + require("../images/makook-designcode.jpg")
          }
        />

        <Header />

        <main>
          <div className="content">
            <Zoom />

            {/* <BrowserView>
          </BrowserView> */}

            {/* <BrowserDetection>{browserHandler}</BrowserDetection> */}

            <div className="content__section">
              <div className="content__main" />
              <h2 className="content__text">Play</h2>
            </div>

            <div className="content__section">
              <div className="content__main">
                <span>simply, Not your average Studio</span>
              </div>
              <h2 className="content__text">MAKOOK</h2>
            </div>

            <div className="content__section">
              <Link className="content__link" to="/designcode">
                <Img
                  className="content__img image_box"
                  fluid={this.props.data.imageOne.childImageSharp.fluid}
                />
              </Link>
              <h2 className="content__text">Design/Code</h2>
            </div>
            <div className="content__section">
              <Link className="content__link" to="/cinematography">
                <Img
                  className="content__img"
                  fluid={this.props.data.imageTwo.childImageSharp.fluid}
                />
              </Link>

              <h2 className="content__text">Cinematography</h2>
            </div>

            {/* <div className="content__section">
              <Link className="content__link" to="/">
                <Img
                  className="content__img"
                  fluid={this.props.data.imageThree.childImageSharp.fluid}
                />
              </Link>
              <h2 className="content__text">3d/animation</h2>
            </div> */}
            <div className="content__section desktop-only">
              <h2 className="content__text">Why Us?</h2>
              <div className="why-us">
                <div>
                  <ReactPlayer
                    className=" desktop-only"
                    url={require("../images/we-are-makook.mp4")}
                    width="100%"
                    height="600px"
                    playing
                    muted
                    loop
                  />
                  <div className="para-text top-notch">
                    <h4 className="little-title">
                      WE BRING IT TO LIFE IN THE MOST AMAZING WAY
                    </h4>
                    <p>
                      We Build the tools to Create and Communicate Your
                      Meaningful difference. Get ideas Going and attract the
                      best talent to serve one purpose YOUR BRAND.
                    </p>
                  </div>
                  <ReactPlayer
                    className=" desktop-only"
                    url="https://www.youtube.com/embed/EPzT0R1AxSo?autoplay=0&showinfo=0&controls=0&loop=1&frameborder=0"
                    width="100%"
                    playing
                    controls="false"
                    muted
                    loop
                  />
                </div>
                <div className="">
                  <div className="para-text">
                    <h4 className="little-title">WE DO WHAT YOU DO</h4>
                    <p>
                      We begin by “knowing nothing” and we immerse our selves in
                      everything you do. We are the users, the audience, the
                      business associate. We want to move in take part of your
                      team and ideas to better curate a solution.
                    </p>
                    <h4 className="little-title">
                      WE HELP FIND WHAT MAKES YOU AMAZING
                    </h4>
                    <p>
                      We call it “Meaningful difference” it is The powerful Idea
                      behind your brand. The thing that separates you from
                      everyone else.
                    </p>
                  </div>
                  <ReactPlayer
                    className=""
                    url={require("../images/makook_apps.mp4")}
                    width="100%"
                    height="700px"
                    playing
                    muted
                    loop
                  />
                </div>
              </div>
            </div>

            <div className="content__section">
              <h2 className="content__text">Contact</h2>
              <div className="content__contact">
                <p className="reach-out">
                  Give us a call, or reach out to us via social media. We’re
                  always open for a chat.
                </p>
                <div className="content__info">
                  <a
                    className="contact--link--footer"
                    href="mailto:hello@makook.space"
                  >
                    hello@makook.space
                  </a>
                  <br></br>
                  <a
                    className="contact--link--footer"
                    href="tel:+213 550 246249"
                  >
                    +213 (550) 246249
                  </a>
                </div>
                <div className="footer-linksr">
                  <a
                    className="contact--link--footer"
                    href="https://www.facebook.com/makook.space"
                  >
                    Facebook
                  </a>
                  <a
                    className="contact--link--footer"
                    href="http://instagram.com/makook.space"
                  >
                    instagram
                  </a>
                  <a
                    className="contact--link--footer"
                    href="https://twitter.com/makookstudio"
                  >
                    Twitter
                  </a>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}
export default IndexPage;

export const IndexQuery = graphql`
  fragment IndexQuery on File {
    childImageSharp {
      fluid(maxWidth: 1400) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export const IndexPicQuery = graphql`
  query {
    imageOne: file(relativePath: { eq: "makook-designcode.jpg" }) {
      ...fluidImage
    }

    imageTwo: file(relativePath: { eq: "cinematography.jpg" }) {
      ...fluidImage
    }

    imageThree: file(relativePath: { eq: "Makook-3D-Animation.jpg" }) {
      ...fluidImage
    }
    imageFoure: file(relativePath: { eq: "Makook_Studio.jpg" }) {
      ...fluidImage
    }
  }
`;
